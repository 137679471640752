import React from 'react'
import { graphql } from 'gatsby'
// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Content from 'components/Content'

const Page = ({ data }) => {
	console.log(data)
	const { wordpressPage: page } = data

	return (
		<Layout>
			<SEO title={page.title} />
			<div className="container py-5">
				<h1>{page.title}</h1>
				<div>
					<Content content={page.content} />
				</div>
			</div>
		</Layout>
	)
}

export default Page

export const query = graphql`
	query PageByID($wordpress_id: Int!) {
		wordpressPage(
			wordpress_id: { eq: $wordpress_id }
		) {
			id
			wordpress_id
			title
			slug
			content
		}
	}
`
